import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/Home/Home.jsx";
import Message from "./pages/Message/Message.jsx";
import Gallery from "./pages/Gallery/Gallery.jsx";
import Contract from "./pages/Contract/Contract.jsx";
import About from "./pages/About/About.jsx";
import Carousel from "./components/Carousal/Carousel.jsx";
import { SpinnerRoundFilled } from "spinners-react";
import { HelmetProvider } from "react-helmet-async";
function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/message" element={<Message />}></Route>
            <Route path="/contact" element={<Contract />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/about" element={<About />}></Route>
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
