import s1 from "./s1.jpg";
import s2 from "./s2.jpg";
import s3 from "./s3.jpg";
import s4 from "./s4.jpg";
import s5 from "./s5.jpg";
import s6 from "./s6.jpg";
import s7 from "./s7.jpg";
const info2 = [
  {
    id: 1,
    img: s1,
  },
  {
    id: 2,
    img: s2,
  },
  {
    id: 3,
    img: s3,
  },
  {
    id: 4,
    img: s4,
  },
  {
      id: 5,
      img: s5,
    },
    {
      id: 6,
      img: s6,
    },
    {
      id: 7,
      img: s7,
    },
];
export default info2;
