import React from 'react';
import { SpinnerRoundFilled } from 'spinners-react';
import logo from '../../assets/logo.png';

function Loader() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <div style={{ position: 'relative', width: '40vw', height: '40vw' }}>
                <SpinnerRoundFilled size={'100%'} thickness={100} />
                <img src={logo} alt="Logo" style={{
                    position: 'absolute',
                    top: '80%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '20vw',
                    height: '20vw',
                    Shadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                }}/>
            </div>
        </div>
    );
}

export default Loader;
