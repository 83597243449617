import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet-async';
import Navbar from "../../components/navbar/Navbar";
import Footer from '../../components/footer/Footer';
import "./Message.css";
import c1 from '../../assets/cover.jpg';
import chairman from '../../assets/ch.jpeg';
import md from '../../assets/md.jpeg';

import aos from 'aos'
import 'aos/dist/aos.css'
import CountUp from 'react-countup';
import logo1 from '../../assets/logo1.png'
import logo2 from '../../assets/logo2.png'
import logo3 from '../../assets/logo3.png'
import logo4 from '../../assets/logo4.png'
import logo5 from '../../assets/logo5.png'
import Loader from '../../components/Loader/Loader';
import TestimonialsSlider from '../../components/TestimonialSlider/TestimonialsSlider';
function Message() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    aos.init({ duration: 2000 });
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>Message from Chairman - Sultan Overseas</title>
        <meta name="description" content="A message from the Chairman and Managing Director of Sultan Overseas." />
        <meta name="keywords" content="Chairman, message, Sultan Overseas, managing director, top management, Altab Hossain, Humayan Kabir" />
      </Helmet>
      {
        loading ? (<Loader />) : (
          <div className="msg-page">
            <div className="navbar-section">
              <Navbar />
            </div>
            <div className="msg-hero-section">
              <div className="msg-img" >
                <img src={c1} alt="cover" className="cover-img" />
                <div className="overlay"></div>
                <h1>Message from Our <span>Chairman & Maneging Director</span></h1>
              </div>
            </div>
            <div className="flag-section">
              <div className="country-flags">
                <img src={logo1} alt="KSA Flag" className="flag" data-aos="zoom-in" />
                <img src={logo2} alt="UAE Flag" className="flag" data-aos="zoom-in" />
                <img src={logo3} alt="Malaysia Flag" className="flag" data-aos="zoom-in" />
                <img src={logo4} alt="Malaysia Flag" className="flag" data-aos="zoom-in" />
                <img src={logo5} alt="EU Flag" className="flag" data-aos="zoom-in" />
              </div>
            </div>
            <div className="msg-sub-section">
              <div className="msg-chairman">
                <div className="left" data-aos="zoom-in-right">
                  <h1>Our <span>Chairman</span></h1>
                  <h2>
                    Md Altaf Hossain
                  </h2>
                  <p>Welcome to Sultan Overseas, a pioneer in manpower recruitment and deployment. Our journey began over two decades ago, driven by the vision to bridge the gap between skilled talent and international opportunities. As a company authorized by Bangladesh's Ministry of Expatriates' Welfare and Overseas Employment, we take immense pride in our commitment to excellence, integrity, and professionalism.

                    At Sultan Overseas, we understand that our success lies in the success of the individuals we recruit and the satisfaction of our clients. We have consistently strived to set benchmarks in the recruitment industry through rigorous assessment processes, dedicated training programs, and a steadfast commitment to our partners' needs. Our goal is to create a seamless experience for both employers and employees, ensuring a perfect match that benefits all parties involved.

                    Thank you for your trust and support. We look forward to continuing our journey together, achieving new milestones, and contributing to the global workforce with the same dedication and passion that has defined us since our inception.</p>
                </div>
                <div className="right" data-aos="zoom-in-left">
                  <img src={chairman} alt="chairman" />
                </div>
              </div>
            </div>

            <div className="quote-section" data-aos="zoom-out">
              <h1><span>"</span> O Allah! Grant me enough
                of what you make lawful so that I may dispense
                with what You make unlawful, and enable me by
                Your Grace to dispense with all but You. <span>"</span>
                <span> - Tirmidhi 3563</span></h1>
            </div>
            <div className="msg-sub-section">
              <div className="msg-chairman">
                <div className="right" data-aos="zoom-in-right">
                  <img src={md} alt="chairman" />
                </div>
                <div className="left" data-aos="zoom-in-left">
                  <h1>Our <span>Managing Director</span></h1>
                  <h2>
                    Md Humauan Kabir
                  </h2>
                  <p>It is my great pleasure to welcome you to Sultan Overseas. Our mission is to provide exceptional recruitment services that meet the diverse needs of our international partners while offering unparalleled opportunities to skilled, semi-skilled, and unskilled workers.

                    Our success story is built on a foundation of trust, reliability, and a deep understanding of the industries we serve. We are dedicated to fostering long-term relationships with our clients by delivering quality manpower solutions that drive growth and success. Our experienced team works tirelessly to identify, train, and deploy candidates who are not only qualified but also align with the values and expectations of our clients.

                    In an ever-evolving global landscape, Sultan Overseas remains committed to innovation, continuous improvement, and maintaining the highest standards of service. We are grateful for the confidence you have placed in us and remain dedicated to achieving excellence in all our endeavors.

                    Thank you for choosing Sultan Overseas. We look forward to serving you with the highest level of professionalism and dedication.</p>
                </div>
              </div>
            </div>
            <div className="testimonials-section">
              <h1 data-aos="zoom-in-down">Testimonials</h1>
              <TestimonialsSlider />
            </div>
            <div className="footer-section">
              <Footer />
            </div>
          </div>
        )
      }
    </>
  );
}

export default Message;
