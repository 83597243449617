import './About.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar2 from "../../components/navbar/Navbar2";
import Footer from '../../components/footer/Footer';
import Navbar from "../../components/navbar/Navbar";
import aos from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup';
import { Parallax } from 'react-parallax';
import a1 from '../../assets/a1.jpg';
import bg from './bg.jpg';
import L1 from './L1.jpeg';
import L0 from './L0.jpeg';
import L2 from './L2.jpeg';
import L3 from './L3.jpeg';
import Loader from '../../components/Loader/Loader';

import companyLogos from '../../assets/company';
import Carousel from '../../components/Carousal/Carousel';

const companyData = [
  { company: "AL AFRAS", country: "Saudi Arabia" },
  { company: "AL BAIK", country: "Saudi Arabia" },
  { company: "AL MAJAL", country: "Saudi Arabia" },
  { company: "AL ZAHRANI", country: "Saudi Arabia" },
  { company: "MaCdonald", country: "Saudi Arabia" },
  { company: "AL SAFARI", country: "Saudi Arabia" },
  { company: "Bin Laden", country: "Saudi Arabia" },
  { company: "AL Harbi", country: "Saudi Arabia" },
  { company: "AL Toukhi", country: "Saudi Arabia" },
  { company: "AL Mashariq", country: "Saudi Arabia" },
  { company: "The Engineer's Office", country: "U.A.E" },
  { company: "Armored Vehicle Manufactured", country: "U.A.E" },
  { company: "United Decoration Company. L.L.C", country: "U.A.E" },
  { company: "Profile Recruitment", country: "U.A.E" },
  { company: "Bosco Aluminum ", country: "U.A.E" },
  { company: "Gulf Tableware", country: "U.A.E" },
  { company: "Dodsal Group", country: "U.A.E" },
  { company: "Increative Interior Design", country: "U.A.E" },
  { company: "Belhasa Group", country: "U.A.E" },
  { company: "Al-Jaber Energy Services Ltd", country: "U.A.E" },
  { company: "B K Gulf L.L.C", country: "U.A.E" },
  { company: "Noukri Recruitment L.L.C", country: "U.A.E" },
  { company: "MBM Dallah Alamflora", country: "U.A.E" },
  { company: "Group 3 Contracting Co. WLL", country: "Qatar" },
  { company: "Al Khayed Co.", country: "Qatar" },
  { company: "Sheik Hamtun Al Alim Co.", country: "Qatar" },
  { company: "Al Doha Manpower", country: "Qatar" },
  { company: "Felda Plantation Sdn Bhd", country: "Malaysia" },
  { company: "Gattni", country: "Malaysia" },
  { company: "Kossan Rubber Industry BHD", country: "Malaysia" },
  { company: "Rahmat Tegguh (M) SND, BHD", country: "Malaysia" },
  { company: "Lebrothers Construction SND, BHD", country: "Malaysia" },
  { company: "Kilang Makanan Mamkk SND, BHD", country: "Malaysia" },
  { company: "Muktafin Security Guard Sdn Bhd", country: "Malaysia" },
  { company: "Laxamana Security Sdn Bhd", country: "Malaysia" },
  { company: "Sekawan Security Sdn Bhd", country: "Malaysia" },
  { company: "Dats Management Sdn Bhd", country: "Malaysia" },
  { company: "Sumo Japanese Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Ship Western Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Soul Hart Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Old Town Café Sdn Bhd", country: "Malaysia" },
  { company: "Papa Rich Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Berger King Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Asia Café & Restaurant Sdn Bhd", country: "Malaysia" },
  { company: "Global Metotried Sdn Bhd", country: "Malaysia" },
  { company: "Grand Dynasty Sdn Bhd", country: "Malaysia" },
  { company: "Hongler Enterprise Sdn Bhd", country: "Malaysia" },
  { company: "URC Engineering Sdn Bhd", country: "Malaysia" },
  { company: "Harta Maintenance Sdn Bhd", country: "Malaysia" }
];

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(true);
  const [visibleRows, setVisibleRows] = useState(10);

  useEffect(() => {
    aos.init({ duration: 2500 });
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const loadMoreRows = () => {
    setVisibleRows(prevVisibleRows => prevVisibleRows + 10);
  };

  return (
    <>
      <Helmet>
        <title>About Us - Sultan Overseas</title>
        <meta name="description" content="Learn about Sultan Overseas, a recruitment agency with over two decades of experience, specializing in skilled, semi-skilled, and unskilled worker recruitment for various countries." />
        <meta name="keywords" content="Sultan Overseas, recruitment, skilled workers, unskilled workers, Saudi Arabia, UAE, Qatar, Malaysia, Europe" />
      </Helmet>
      {
        loading ? (<Loader />) : (
          <div className='aboutpage'>
            <div className="navbar-section">
              <Navbar />
            </div>
            <div className="about-hero-section">
              <div className="abt-img">
                <img src={a1} alt="cover" className="cover-img" />
                <div className="overlay">
                  <h1 data-aos="zoom-in-down">All About <span> Sultan Overseas</span></h1>
                </div>
              </div>
            </div>
            <div className="abt-sub-section" data-aos="fade-up" data-aos-duration="2000">
              <div className="bragging">
                <div className="countup">
                  <div className="number-count">
                    <h1> <CountUp end={10} duration={10} delay={2} />+</h1>
                    <h3>Total Country</h3>
                  </div>
                  <div className="number-count">
                    <h1> <CountUp end={20} duration={10} delay={2} />+</h1>
                    <h3>Total Experience</h3>
                  </div>
                  <div className="number-count">
                    <h1> <CountUp start={99900} end={100000} duration={10} delay={2} />+</h1>
                    <h3>Manpower Exported</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="abt-sub-section-2">
              <div className="abt-heading">
                <h1>Your Trusted Partner In Manpower</h1>
              </div>
              <div className="abt-sub-section-text">
                <p>
                  Sultan Overseas, authorized by Bangladesh's Ministry of Expatriates' Welfare and Overseas Employment (License No. RL 906), specializes in recruiting skilled, semi-skilled, and unskilled workers for various countries, including Saudi Arabia, Malaysia, UAE, Qatar, and Europe. With over two decades of experience, we rigorously assess candidates for suitability, ensuring only the most qualified individuals are selected. Our dedicated team is committed to meeting the diverse needs of our international partners. We are eager to serve your esteemed company with professionalism and expertise in recruitment, training, and deployment.</p>
              </div>
            </div>
            <Parallax blur={3} bgImage={bg} bgImageAlt="Sultan overseas" strength={400}>
              <h1 className='content'>
              </h1>
            </Parallax>
            <div className="abt-licens-section">
              <div className="abt-licens-heading">
                <h1>Our <span>License</span></h1>
              </div>
              <div className="abt-licens-img">
                <div className="img1">
                  <img src={L1} alt="lic1" className="lic-img" />
                  <img src={L0} alt="lic1" className="lic-img" />
                </div>
                <div className="img2">
                  <img src={L2} alt="lic1" className="lic-img" />
                  <img src={L3} alt="lic1" className="lic-img" />
                </div>
              </div>
            </div>
            <div className="abt-partners-section">
              <div className="abt-partners-heading">
                <h1>Our <span>Partners</span> & Affilated Company</h1>
              </div>
              <div className="company-logos">
                <Carousel />
              </div>
            </div>
            <div className="abt-table-section">
              <div className="table-heading">
                <h1>List of <span>Companies</span> & Countries</h1>
              </div>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyData.slice(0, visibleRows).map((data, index) => (
                      <tr key={index}>
                        <td>{data.company}</td>
                        <td>{data.country}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {visibleRows < companyData.length && (
                  <button onClick={loadMoreRows} className="load-more-btn">Load More</button>
                )}
              </div>
            </div>
            <div className="footer-section">
              <Footer />
            </div>
          </div>
        )
      }
    </>
  )
}

export default About;
