import g1 from "../assets/gallery/office1.jpeg";
import g2 from "../assets/gallery/office2.jpeg";
import g3 from "../assets/gallery/office4.jpeg";

const gallery = [
  {
    id: 1,
    img: g3
  },
  {
    id: 2,
    img: g2
  },
  {
    id: 3,
    img: g1
  },
];

export default gallery;

