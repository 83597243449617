import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logo.png';
import { navItems } from './NavItems';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons

function Navbar() {
    const [fix, setFix] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const handleScroll = () => {
        if (window.scrollY >= 10) {
            setFix(true);
        } else {
            setFix(false);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    window.addEventListener("scroll", handleScroll);

    return (
        <>
            <nav className={fix ? "navbar fixed" : "navbar"}>
                <div className='logo'>
                    <Link to="/">
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>
                <div className='menu-icon' onClick={toggleMenu}>
                    {menuOpen ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={menuOpen ? 'nav-items active' : 'nav-items'}>
                    {navItems.map((item) => {
                        const isActive = location.pathname === item.path;
                        return (
                            <li key={item.id} className={item.cName}>
                                <Link to={item.path} className={isActive ? 'active' : ''}>{item.title}</Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </>
    );
}

export default Navbar;
