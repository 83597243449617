const companyData = [
      { company: "AL AFRAS", country: "Saudi Arabia" },
      { company: "AL BAIK", country: "Saudi Arabia" },
      { company: "AL MAJAL", country: "Saudi Arabia" },
      { company: "AL ZAHRANI", country: "Saudi Arabia" },
      { company: "MaCdonald", country: "Saudi Arabia" },
      { company: "AL SAFARI", country: "Saudi Arabia" },
      { company: "Bin Laden", country: "Saudi Arabia" },
      { company: "AL Harbi", country: "Saudi Arabia" },
      { company: "AL Toukhi", country: "Saudi Arabia" },
      { company: "AL Mashariq", country: "Saudi Arabia" },
      { company: "The Engineer's Office", country: "U.A.E" },
      { company: "Armored Vehicle Manufactured", country: "U.A.E" },
      { company: "United Decoration Company. L.L.C", country: "U.A.E" },
      { company: "Profile Recruitment", country: "U.A.E" },
      { company: "Bosco Aluminum ", country: "U.A.E" },
      { company: "Gulf Tableware", country: "U.A.E" },
      { company: "Dodsal Group", country: "U.A.E" },
      { company: "Increative Interior Design", country: "U.A.E" },
      { company: "Belhasa Group", country: "U.A.E" },
      { company: "Al-Jaber Energy Services Ltd", country: "U.A.E" },
      { company: "B K Gulf L.L.C", country: "U.A.E" },
      { company: "Noukri Recruitment L.L.C", country: "U.A.E" },
      { company: "MBM Dallah Alamflora", country: "U.A.E" },
      { company: "Group 3 Contracting Co. WLL", country: "Qatar" },
      { company: "Al Khayed Co.", country: "Qatar" },
      { company: "Sheik Hamtun Al Alim Co.", country: "Qatar" },
      { company: "Al Doha Manpower", country: "Qatar" },
      { company: "Felda Plantation Sdn Bhd", country: "Malaysia" },
      { company: "Gattni", country: "Malaysia" },
      { company: "Kossan Rubber Industry BHD", country: "Malaysia" },
      { company: "Rahmat Tegguh (M) SND, BHD", country: "Malaysia" },
      { company: "Lebrothers Construction SND, BHD", country: "Malaysia" },
      { company: "Kilang Makanan Mamkk SND, BHD", country: "Malaysia" },
      { company: "Muktafin Security Guard Sdn Bhd", country: "Malaysia" },
      { company: "Laxamana Security Sdn Bhd", country: "Malaysia" },
      { company: "Sekawan Security Sdn Bhd", country: "Malaysia" },
      { company: "Dats Management Sdn Bhd", country: "Malaysia" },
      { company: "Sumo Japanese Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Ship Western Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Soul Hart Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Old Town Café Sdn Bhd", country: "Malaysia" },
      { company: "Papa Rich Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Berger King Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Asia Café & Restaurant Sdn Bhd", country: "Malaysia" },
      { company: "Global Metotried Sdn Bhd", country: "Malaysia" },
      { company: "Grand Dynasty Sdn Bhd", country: "Malaysia" },
      { company: "Hongler Enterprise Sdn Bhd", country: "Malaysia" },
      { company: "URC Engineering Sdn Bhd", country: "Malaysia" },
      { company: "Harta Maintenance Sdn Bhd", country: "Malaysia" }
];
export default companyData;