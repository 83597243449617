import g1 from './0.gif'
import g2 from './1.gif'
import g3 from './2.gif'
import g4 from './3.gif'

const info = [
      {
            id: 1,
            img: g3,
            text: "Recruiter License - 906"
      },
      {
            id: 2,
            img: g2,
            text: "Manpower exported 1,00,000+"
      },
      {
            id: 3,
            img: g1,
            text: "Total Country 10+"
      },
      {
            id: 4,
            img: g4,
            text: "25+ Years Experience"
      }
]
export default info