import React, { useEffect, useState } from 'react';
import './Gallery.css';
import Navbar from "../../components/navbar/Navbar";
import Footer from '../../components/footer/Footer';
import aos from 'aos';
import 'aos/dist/aos.css';
import Loader from '../../components/Loader/Loader';

import gallery2 from '../../assets/gallery2';
import gallery from '../../assets/gallery';
import s1 from '../../assets/gg.webp';

import 






ImageSlider from '../../components/image slider/ImageSlider';
function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    aos.init({ duration: 2500 });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);



  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="gallery">
          <div className="navbar-section">
            <Navbar />
          </div>
          <div className="gallery-hero-section">
            <div className="glry-img">
              <img src={s1} alt="cover" className="cover-img" />
              <div className="overlay">
                <h1 data-aos="zoom-in-down">
                  <span>Explore</span>Our Story in Pictures
                </h1>
              </div>
            </div>
          </div>
          <div className="glry-1">
            {gallery.map((item) => (
              <div className="office-img" key={item.id} >
                <img src={item.img} alt="cover" data-aos="zoom-in" data-aos-duration="3000"/>
              </div>
            ))}
          </div>
          <div className="glry-2">
            <h1>Foreign Deligation</h1>
            <div className='gg2'>
              {gallery2.map((item) => (
                <div className="office-img" key={item.id} >
                  <img src={item.img} alt="cover" data-aos="zoom-in"/>
                </div>
              ))}
            </div>
          </div>
          <div className="glry-3">
            <h1>Our Office</h1>
            <div className="gg2">
            {gallery.map((item) => (
              <div className="office-img" key={item.id} >
                <img src={item.img} alt="cover" data-aos="zoom-in"/>
              </div>
            ))}
            </div>

          </div>
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default Gallery;
