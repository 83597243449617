import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './Contract.css';
import Navbar from "../../components/navbar/Navbar";
import Footer from '../../components/footer/Footer';
import Loader from '../../components/Loader/Loader'; // Import the Loader component

import a1 from './cover.jpg';
import icon from './icon1.gif';
import Form from '../../components/form/Form';

import aos from 'aos';
import 'aos/dist/aos.css';

function Contract() {
  const [loading, setLoading] = useState(true); // State to control loader visibility

  useEffect(() => {
    aos.init({
      duration: 2000,
    });
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us - Sultan Overseas</title>
        <meta name="description" content="Get in touch with Sultan Overseas for recruitment and manpower solutions. Contact us for any inquiries or support." />
        <meta name="keywords" content="Sultan Overseas, contact, recruitment, manpower solutions, Dhaka, Bangladesh" />
      </Helmet>
      {loading ? (
        <Loader /> // Display loader while loading
      ) : (
        <div className="cntpage">
          <div className="navbar-section">
            <Navbar />
          </div>
          <div className="about-hero-section">
            <div className="abt-img">
              <img src={a1} alt="cover" className="cover-img" />
              <div className="overlay">
                <h1>Contact with<span>Sultan Overseas</span></h1>
              </div>
            </div>
          </div>
          <div className="contact-info-section">
            <div className="info-container">
              <div className="left-contact" data-aos="fade-right">
                <div className="contact-heading">
                  <h1>Points of Contact</h1>
                </div>
                <div className="contact-icon">
                  <img src={icon} alt="contact icon" />
                  <div className='address'>
                    <h2>Dhaka | Bangladesh</h2>
                    <h3>H 101, Phulu Tower, Kakoli, Banani, Dhaka</h3>
                  </div>
                </div>
                <div className="contact-icon">
                  <img src={icon} alt="contact icon" />
                  <div className='address'>
                    <h2>Contact Number | Bangladesh</h2>
                    <h3>01717425855 | 01913478863</h3>
                  </div>
                </div>
              </div>
              <div className="right-contact" data-aos="fade-left">
                <div className="contact-form">
                  <Form />
                </div>
              </div>
            </div>
          </div>
          <div className="map-section">
            <iframe
              title='sultan-overseas'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.7003995704986!2d90.39805187606606!3d23.793680787112148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c71175d003d9%3A0xf040b04524c1fbfd!2sSultan%20Overseas!5e0!3m2!1sen!2sbd!4v1716114629511!5m2!1sen!2sbd"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              data-aos="zoom-in"
            ></iframe>
          </div>
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default Contract;
