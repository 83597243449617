import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialsSlider.css'; 
import companyData from '../../assets/companyData';

const TestimonialsSlider = () => {
  const settings = {
    dots: false, // Turn off pagination
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {companyData.map((data, index) => {
        let comment = '';
        if ((index + 1) % 2 === 0) {
          comment = `Sultan Overseas has been an excellent partner for ${data.company} in ${data.country}. We appreciate their dedication and professionalism.`;
        } else if ((index + 1) % 3 === 0) {
          comment = `Working with Sultan Overseas has been a rewarding experience for ${data.company}. Their commitment to quality is commendable.`;
        } else if ((index + 1) % 5 === 0) {
          comment = `We are grateful for the outstanding support provided by Sultan Overseas to ${data.company}. Their expertise has been invaluable.`;
        } else {
          comment = `Sultan Overseas has proven to be a reliable partner for ${data.company} in ${data.country}. We highly recommend their services.`;
        }
        
        return (
          <div key={index}>
            <div className="testimonial-card" >
              <h3>{data.company} | {data.country}</h3>
              <p>{comment}</p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default TestimonialsSlider;
