import React, { useEffect, useState  } from 'react'
import { Helmet } from 'react-helmet-async';
import { Typewriter } from "react-simple-typewriter"
import Navbar from "../../components/navbar/Navbar";
import Hero from '../../components/hero/Hero';
import Footer from '../../components/footer/Footer';
import './Home.css'
import h1 from '../../assets/welcom.jpg'
import info from '../../assets/info'
import sectors from '../../assets/info2'
import map from '../../assets/map.jpg'
import logo1 from '../../assets/logo1.png'
import logo2 from '../../assets/logo2.png'
import logo3 from '../../assets/logo3.png'
import logo4 from '../../assets/logo4.png'
import logo5 from '../../assets/logo5.png'

import aos from 'aos'
import 'aos/dist/aos.css'
import Loader from '../../components/Loader/Loader';
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    aos.init();
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - Sultan Overseas</title>
        <meta name="description" content="Sultan Overseas specializes in recruiting skilled workers for various countries." />
        <meta name="keywords" content="recruitment, overseas jobs, skilled workers, Saudi Arabia, UAE, Malaysia, visa, work visa, work visa from Bangladesh, job at saudi" />
      </Helmet>
      {
        loading ? (<Loader />) : (
          <div className="homepage">
            <div className="navbar-section">
              <Navbar />
            </div>
            <div className="hero-section">
              <Hero />
            </div>
            <div className="flag-section">
              <div className="country-flags">
                <img src={logo1} alt="Sultan Overseas Saudi flag" className="flag" data-aos="zoom-in" />
                <img src={logo2} alt="Sultan Overseas UAE Flag" className="flag" data-aos="zoom-in" />
                <img src={logo3} alt="Sultan Overseas Malaysia Flag" className="flag" data-aos="zoom-in" />
                <img src={logo4} alt="Sultan Overseas Malaysia Flag" className="flag" data-aos="zoom-in" />
                <img src={logo5} alt="Sultan Overseas Malaysia Flag" className="flag" data-aos="zoom-in" />
              </div>
            </div>
            <div className="sub-section">
              <div className="left-col" data-aos="fade-right">
                <h1>Welcome to <span>Sultan Overseas</span></h1>
                <p>Sultan Overseas, authorized by Bangladesh's Ministry of Expatriates' Welfare and Overseas Employment (License No. RL 906), specializes in recruiting skilled, semi-skilled, and unskilled workers for various countries, including Saudi Arabia, Malaysia, UAE, Qatar, and Europe. With over two decades of experience, we rigorously assess candidates for suitability, ensuring only the most qualified individuals are selected. Our dedicated team is committed to meeting the diverse needs of our international partners. We are eager to serve your esteemed company with professionalism and expertise in recruitment, training, and deployment.</p>
                <div className="info-con">
                  {
                    info.map((item, index) => {
                      return (
                        <div className="info-item">
                          <img src={item.img} alt="Sultan Overseas" />
                          <p>{item.text}</p>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="right-col" data-aos="fade-left">
                <img src={h1} alt="" />
              </div>
            </div>
            <div className="sub-section-2">
              <h1><span>Our Expertise</span> in Industrial Sectors</h1>
              <div className="sectors-container">
                {
                  sectors.map((item, index) => {
                    return (
                      <div className="sector-item">
                        <img src={item.img} alt="Sultan Overseas" data-aos="zoom-in" />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="sub-section-3">
              <h1>We Export Manpower To {
                <Typewriter words={['Saudi Arabia', 'UAE', 'Malaysia', 'Europe', 'Qatar']} loop={false} deleteSpeed={80} delaySpeed={2000} />
              }</h1>
              <div className="map">
                <img src={map} alt="Sultan Overseas - Home" data-aos="zoom-in" />
              </div>
            </div>
            <div className="sub-section-3">
              <h1 style={{ textAlign: 'center', marginBottom: '50px' }}>Our <span>Location</span></h1>
              <div className="map-2">
                <iframe
                  title='sultan-overseas'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.7003995704986!2d90.39805187606606!3d23.793680787112148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c71175d003d9%3A0xf040b04524c1fbfd!2sSultan%20Overseas!5e0!3m2!1sen!2sbd!4v1716114629511!5m2!1sen!2sbd"
                  width="1280"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  data-aos="zoom-in"
                ></iframe>
              </div>
            </div>
            <div className="footer-section">
              <Footer />
            </div>
          </div>
        )
      }

    </>
  )
}

export default Home