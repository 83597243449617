import React, { useEffect, useState, useRef } from 'react'
import './Footer.css';
import companyLogo from '../../assets/logo.png'; // replace with the path to your logo
import aos from 'aos'
import 'aos/dist/aos.css'
const Footer = () => {
      useEffect(() => {
            aos.init({ duration: 2000 });
          }, []);
      return (
            <footer className="footer">
                  <div className="footer-content">
                        <div className="logo">
                              <img src={companyLogo} alt="Company Logo" className="footer-logo" />
                        </div>
                        <div className="footer-address">
                              <p>Sultan Overseas</p>
                              <p>Phulu Tower, Kakoli, Banani</p>
                              <p>Dhaka, Bangladesh</p>
                              {/* <p>Email: info@sultanoverseas.com</p> */}
                              {/* <p>Phone: +880 1234 567890</p> */}
                        </div>
                        <div className="footer-links">
                              <h4>Quick Links</h4>
                              <a href="/about">About Us</a>
                              <a href="/career">Career</a>
                              <a href="/contact">Contact</a>
                        </div>

                        <div className="footer-social">
                        <h4>Social Links</h4>
                              <a href="https://www.facebook.com/sultanoverseasbd" target="_blank" rel="noopener noreferrer">Facebook</a>
                              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                              <a href="https://www.linkedin.com/company/sultanoverseas" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                        </div>
                  </div>
                  <div className="footer-bottom">
                        <p>&copy; 2024 Sultan Overseas. All rights reserved.</p>
                  </div>
            </footer>
      );
};

export default Footer;
