import c0 from './c0.jpeg'
import c1 from './c1.jpeg'
import c2 from './c2.jpeg'
import c3 from './c3.jpeg'
import c4 from './c4.jpeg'
import c5 from './c5.jpeg'
import c6 from './c6.jpeg'
import c7 from './c7.jpeg'
import c8 from './c8.jpeg'
import c9 from './c9.jpeg'



const company = [
      {
          id: 1,
          img: c0,
          company: "Al-Baik"
      },
      {
          id: 2,
          img: c1,
          company: "McDonald's"
      },
      {
          id: 3,
          img: c2,
          company: "Nebras"
      },
      {
          id: 4,
          img: c3,
          company: "Sasco"
      },
      {
          id: 5,
          img: c4,
          company: "Mahara"
      },
      {
          id: 6,
          img: c5,
          company: "Zahrani"
      },
      {
          id: 7,
          img: c6,
          company: "Afras"
      },
      {
          id: 8,
          img: c7,
          company: "Seder Group"
      },
      {
          id: 9,
          img: c8,
          company: "Alyamama"
      },
      {
          id: 10,
          img: c9,
          company: "AlMahajl Alarabi"
      }
  ];
export default company