import React from 'react';
import Slider from 'react-slick';
import images from '../../assets/company';
import './Carousel.css';

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default for desktop
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5, // Number of slides for tablets and small desktops
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Number of slides for large mobile devices
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3, // Number of slides for small mobile devices
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image) => (
          <div key={image.id} className="carousel-slide">
            <img src={image.img} alt={image.company} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
