import s1 from "../assets/gallery/f1.png";
import s2 from "../assets/gallery/f2.png";
import s3 from "../assets/gallery/f3.png";
import s4 from "../assets/gallery/f4.png";
import s5 from "../assets/gallery/f5.png";
import s6 from "../assets/gallery/f6.png";

const gallery2 = [
      {
        id: 1,
        img: s1
      },
      {
        id: 2,
        img: s2
      },
      {
        id: 3,
        img: s3
      },
      {
        id: 4,
        img: s4
      },
      {
        id: 5,
        img: s5
      },
      {
        id: 6,
        img: s6
      },
    ];

export default gallery2