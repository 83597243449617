export const navItems = [
    {
      id: 1,
      title: "Home",
      path: "/",
      cName: "nav-item",
    },
    {
      id: 2,
      title: "Message",
      path: "/message",
      cName: "nav-item",
    },
    {
      id: 3,
      title: "About Us",
      path: "/about",
      cName: "nav-item",
    },
    {
      id: 4,
      title: "Contact",
      path: "/contact",
      cName: "nav-item",
    },
    {
        id: 5,
        title: "Gallery",
        path: "/gallery",
        cName: "nav-item",
      },
  ];

  export const aboutDropdown = [
    {
      id: 1,
      title: "Profile",
      path: "/profile",
      cName: "submenu-item",
    },
    {
      id: 2,
      title: "Partners",
      path: "/partners",
      cName: "submenu-item",
    },
  ];