import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Form.css';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  message: Yup.string().required('Message is required')
});

const Form = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    validationSchema,
    onSubmit: values => {
      const whatsappNumber = "01717425855"; 
      const whatsappMessage = `Hello, my name is ${values.name}. My email is ${values.email}. ${values.message}`;
      
      // Encode the message
      const encodedMessage = encodeURIComponent(whatsappMessage);
      
      // Construct the WhatsApp URL
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodedMessage}`;
      
      // Open WhatsApp
      window.open(whatsappUrl, '_blank');
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-container">
        <h1>Let's Start a Conversation</h1>
        <div className="input-field">
          <label>Full Name</label>
          <input
            type="text"
            name="name"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
        </div>
        <div className="input-field">
          <label>Email</label>
          <input
            type="email"
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
        </div>
        <div className="input-field">
          <label>Message</label>
          <textarea
            name="message"
            {...formik.getFieldProps('message')}
          />
          {formik.touched.message && formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
        </div>
        <button className="submit-btn" type="submit">Send Us</button>
      </div>
    </form>
  );
};

export default Form;
