import React, { useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Hero.css';

// importing images
import img1 from '../../assets/KSA.webp';
import img2 from '../../assets/Dubai.jpg';
import img3 from '../../assets/qatar.webp';
import img4 from '../../assets/europe.webp';
import img5 from '../../assets/malaysia.jpg';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import aos  from 'aos'
import 'aos/dist/aos.css'
function Hero() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
  }

  useEffect(() => {
    aos.init({duration:2000});
  }, [])
  return (
    <div className='hero-container'>
      <div className="hero-text" >
        <h1 data-aos="zoom-in-up">Support our foreign friends & uplift our nation</h1>
        <p data-aos="zoom-in-up">Your Trusted Partner in Manpower Solutions </p>
        <button data-aos="fade-right" className='hero-btn' onClick={() => window.location.href = '/contact'}>Contract Us <FaArrowRight size='1.3em'/></button>
      </div>
      <Slider className="hero-slider" {...settings}>
        <div className="hero-slide">
          <div className="hero-overlay"></div>
          <img src={img1} alt="Sultan Overseas" />
        </div>
        <div className="hero-slide">
          <div className="hero-overlay"></div>
          <img src={img2} alt="" />
        </div>
        <div className="hero-slide">
          <div className="hero-overlay"></div>
          <img src={img3} alt="Sultan Overseas" />
        </div>
        <div className="hero-slide">
          <div className="hero-overlay"></div>
          <img src={img4} alt="Sultan Overseas" />
        </div>
        <div className="hero-slide">
          <div className="hero-overlay"></div>
          <img src={img5} alt="Sultan Overseas" />
        </div>
      </Slider>
    </div>
  )
}

export default Hero